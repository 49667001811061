import React from "react"

import TextField from "@material-ui/core/TextField"
import { useField } from "formik"
import { makeStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"

const styles = makeStyles((theme) => ({
  textBox: {
    fontFamily: "visby",
    marginBottom: "0.2em",
    fontSize: "0.6em",
  },
}))

const CustomTextField = ({
  type,
  label,
  placeholder,
  InputProps,
  ...props
}) => {
  const [field, meta] = useField(props)
  const classes = styles()
  const errorText = meta.error && meta.touched ? meta.error : ""

  const onSelect = () => {
    console.log("sdfsdfsdf")
  }
  return (
    <TextField
      label={label}
      variant="outlined"
      required
      fullWidth
      select
      onSelect={onSelect}
      onChange={onSelect}
      type={type}
      placeholder={placeholder}
      {...field}
      helperText={errorText}
      error={!!errorText}
      InputProps={InputProps}
      className={classes.textBox}
    >
      <MenuItem key={"motorcycle"} value={"motorcycle"}>
        Motorcycle
      </MenuItem>
      <MenuItem key={"bike"} value={"bike"}>
        Bike
      </MenuItem>
      <MenuItem key={"walk"} value={"walk"}>
        Walk
      </MenuItem>
    </TextField>
  )
}

export default CustomTextField
