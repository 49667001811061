import React from "react"
import Page from "../components/spark-express/page"
import RegisterComponent from "../components/spark-express/register"

const Register = () => {
  return (
    <Page>
      <RegisterComponent />
    </Page>
  )
}

export default Register
