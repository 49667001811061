import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton'

const FullScreenDialog = ({ open = true, onClose = () => { } , children = null}) => {

	return <Dialog fullScreen open={open}>
		<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<IconButton onClick={onClose}>
				<CloseIcon />
			</IconButton >
		</div>
			{children}
	</Dialog>
}


export default FullScreenDialog