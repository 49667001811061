import React, { useState } from "react"
import {DialogTitle, Dialog, DialogContent, Button, DialogActions} from '@material-ui/core'
import Component from "./component"
import { register, signIn } from "../../../api/spark-express/auth"
import { setUser } from "../../../services/auth"
import { navigate } from "gatsby"


const MessageDialog = ({onClose = () => {}, open = false, title = 'title', message =  'message'}) => {
  return <Dialog open = {open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent style = {{
      fontFamily: 'visby'
    }}>{message}</DialogContent>
    <DialogActions>
      <Button onClick={onClose} style = {{
        backgroundColor: "#FFCF10",
        width: '100%'
      }} >
        Okay po
      </Button>
    </DialogActions>
  </Dialog>
}

const RegisterContainer = () => {
  const [loading, setLoading] = useState(false)
  const [dialog, setDialogStatus] = useState({status: false, message: '', title: ''})

  const registerRider = (data) => {
    console.log(data)

    setLoading(true)
    if(!data.agreement){
      setDialogStatus({status: true, title: 'Sandali lang po', message: 'Kailangan niyo po sumang-ayon sa Cooperation Agreement at Data Privacy Policy, maraming salamat po!'})
      return 
    }

    register(data)
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          signIn(data.email, data.password).then((response) => {
            if (response.status === 200) {
              response.json().then((user) => {
                setUser(user)
                navigate("/documents")
              })
            }
          })
        } else {
          response.json().then((res) => {
            if(res.error.includes('email already exists')){
              setDialogStatus({status: true, message: 'Sandali lang po', title: 'May nakaregister na po na account sa email na binigay niyo.'})
            }
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })

  }
  return <> 
    <MessageDialog open = {dialog.status}  onClose = {() => {
      setDialogStatus({...dialog, status: false})
    }} title = {dialog.title} message = {dialog.message}/>
      <Component loading = {loading} onSubmit={registerRider} />
    </>
}
export default RegisterContainer
