import React, { useState } from "react"

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import VisibilityIcon from "@material-ui/icons/Visibility"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import { IconButton } from "@material-ui/core"
import Typography from '@material-ui/core/Typography'

import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"

import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import { Formik, Form } from "formik"

import CustomTextField from "../login/CustomTextField"
import CustomSelectField from "./customSelectField"
import { Link } from 'gatsby'

import DocumentDialog from './DocumentDialog'
import CheckBox from "../checkbox"

const styles = makeStyles((theme) => ({
  textBox: {
    fontFamily: "visby",
    marginBottom: "0.2em",
    fontSize: "0.6em",
  },
  form: {
    marginTop: "14em",
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
    backgroundColor: "#FFCF10",
  },
}))

const RegisterComponent = ({ loading, onSubmit }) => {
  const classes = styles()
  const [showPassword, setShowPassword] = useState(false)
  const [cooperationAgreement, setCooperationAgreementStatus] = useState({
    open: false,
    accepted: false
  })

  const [dataPrivacy, setDataPrivacyStatus] = useState({
    open: false,
    accepted: false
  })

  return (
    <>
      <DocumentDialog open={cooperationAgreement.open} onClose={() => setCooperationAgreementStatus({...cooperationAgreement, open: false})} link='https://drive.google.com/file/d/1rO8HjjlMjLqpE2N2RLobUxc59BJfZrro/preview' />
      <DocumentDialog open={dataPrivacy.open} onClose={() => setDataPrivacyStatus({ ...dataPrivacy, open: false })} link='https://drive.google.com/file/d/1oihkqHfBluSN7MRLGpgM79j4SfDG2tzW/preview' />
      <Container  className={classes.form}>
        <Formik initialValues={{ email: "", password: "",agreement: false}} onSubmit={onSubmit}>
          {({handleChange}) => (
            <>
              <Typography variant="h4">Spark Express Rider Registration</Typography>
              <Form>
                <Grid container spacing={1} alignItems="flex-end" margin>
                  <Grid item xs={12} className={classes.textBox}>
                    <CustomTextField label="Buong pangalan" name="name" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" margin>
                  <Grid item xs={12} className={classes.textBox}>
                    <CustomTextField label="Address" name="address" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" margin>
                  <Grid item xs={12} className={classes.textBox}>
                    <CustomTextField label="Baranggay" name="brgy" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" margin>
                  <Grid item xs={12} className={classes.textBox}>
                    <CustomTextField label="Rehiyon" name="region" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" margin>
                  <Grid item xs={12} className={classes.textBox}>
                    <CustomTextField label="Municipality/City" name="municipality" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" margin>
                  <Grid item xs={12} className={classes.textBox}>
                    <CustomTextField label="Driver's License Number" name="drivers_license_number" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" margin>
                  <Grid item xs={12} className={classes.textBox}>
                    <CustomSelectField label="Ano gamit mo?" name="transportationType" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" margin>
                  <Grid item xs={12} className={classes.textBox}>
                    <CustomTextField label="Email" name="email" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" margin>
                  <Grid item xs={12} className={classes.textBox}>
                    <CustomTextField label="CP number" name="phone" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" margin>
                  <Grid item xs={12} className={classes.textBox}>
                    <CustomTextField
                      label="Password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setShowPassword(!showPassword)
                            }}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <CheckBox name = "agreement" onChange = {handleChange} label = {<div style = {{
                  fontSize: '0.8em'
                }}>I agree to <span onClick={() => setCooperationAgreementStatus({...cooperationAgreement, open: true})} style = {{
                  color: 'blue'
                }}>Cooperation Agreement</span> and the <span onClick={() => setDataPrivacyStatus({...dataPrivacy, open: true})} style = {{
                  color: 'blue'
                }}>Data Privacy</span>.</div>}/>
                <Button
                  disabled={loading}
                  type="submit"
                  fullWidth
                  className={classes.submit}
                >
                  {loading ? "Maghintay lamang po..." : "Register"}
                </Button>
              </Form>
            </>
          )}
        </Formik>
        <Link to='/'><Typography variant='subtitle1'>May account ka na ba? Login ka na dito </Typography></Link>
      </Container>
    </>
  )
}

export default RegisterComponent
