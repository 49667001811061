import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';



const CheckBox  = ({onChange, label, name}) => {
	return <FormControlLabel
            control={<Checkbox name= {name} onChange={onChange}/>}
            label= {label}
          />
}

export default CheckBox