import React from 'react'
import FullScreenDialog from "../dialogs/FullScreenDialog"



let w = typeof window === 'undefined'  ? {innerHeight: 0, innerWidth: 0} : window 

const DocumentDialog = ({ open, onClose, link }) => {
	return <FullScreenDialog open={open} onClose={onClose}>
		<iframe
			src={link}
			height={w.innerHeight - 50}
			width={(w.innerWidth > 444 ? 444 : w.innerWidth)}
			frameBorder={0}
		></iframe>
	</FullScreenDialog>
}


export default DocumentDialog
